<template>
  <v-row>
    <v-col
      cols="12"
      md="10"
    >
      <v-autocomplete
        v-model="proveedor.proveedor_id"
        :items="itemsProveedor"
        :search-input.sync="searchProveedor"
        hide-details
        hide-selected
        :label="$t('lbl.proveedor')"
        outlined
        dense
        item-text="name_comercial"
        item-value="id"
        @change="setProveedor"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.proveedor') }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item }">
          <span v-text="item.name_comercial"></span>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="error"
            v-bind="attrs"
            v-on="on"
            @click="deleteProveedorApi(pos)"
          >
            <v-icon small>
              mdi-delete-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="proveedor.user"
        label="Usuario"
        outlined
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="proveedor.password"
        :label="$t('lbl.password')"
        :type="isPasswordVisible ? 'text' : 'password'"
        outlined
        dense
        placeholder="············"
        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
        hide-details
        @click:append="isPasswordVisible = !isPasswordVisible"
      ></v-text-field>
    </v-col>

    <v-col
      cols="12"
      md="12"
    >
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  props: {
    proveedores: {
      type: Array,
      required: true,
    },
    proveedor: {
      type: Object,
      required: true,
    },
    pos: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepForm: null,
      icons: {
        mdiClose,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      currencies: [],
      isPasswordVisible: false,
      itemsProveedor: [],
      searchProveedor: null,
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
    }),
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  created() {
    if (this.proveedor.proveedor_id) {
      this.itemsProveedor = this.proveedores.filter(e => e.id === this.proveedor.proveedor_id)
    }
  },
  methods: {
    ...mapMutations(['deleteProveedorApi']),
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    setProveedor() {
      if (this.proveedor.proveedor_id) {
        this.proveedor.proveedor_slug = this.proveedores.filter(e => e.id === this.proveedor.proveedor_id)[0].slug
      } else {
        this.proveedor.proveedor_slug = null
      }
    },
  },
}
</script>
