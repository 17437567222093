var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsProveedor,"search-input":_vm.searchProveedor,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.proveedor'),"outlined":"","dense":"","item-text":"name_comercial","item-value":"id"},on:{"update:searchInput":function($event){_vm.searchProveedor=$event},"update:search-input":function($event){_vm.searchProveedor=$event},"change":_vm.setProveedor},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.proveedor'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name_comercial)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name_comercial))])],1)]}}]),model:{value:(_vm.proveedor.proveedor_id),callback:function ($$v) {_vm.$set(_vm.proveedor, "proveedor_id", $$v)},expression:"proveedor.proveedor_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteProveedorApi(_vm.pos)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Usuario","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.proveedor.user),callback:function ($$v) {_vm.$set(_vm.proveedor, "user", $$v)},expression:"proveedor.user"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.password'),"type":_vm.isPasswordVisible ? 'text' : 'password',"outlined":"","dense":"","placeholder":"············","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.proveedor.password),callback:function ($$v) {_vm.$set(_vm.proveedor, "password", $$v)},expression:"proveedor.password"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }